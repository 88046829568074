var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            fullscreen: false,
            title: _vm.titleBoletoModal,
            active: _vm.popupBoletoDetails,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupBoletoDetails = $event
            },
          },
        },
        [
          _c("table", { attrs: { width: "100%" } }, [
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _c("vs-divider", { attrs: { position: "left" } }, [
                    _vm._v("Dados Gerais"),
                  ]),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Status")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.boleto.status == "1" ? "Gerado" : "Liquidado")
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Data de Processamento")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.data_processamento &&
                      _vm.formatDateTime(_vm.boleto.data_processamento, "date")
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Data do Documento")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.data_documento &&
                      _vm.formatDateTime(_vm.boleto.data_documento, "date")
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Data de Vencimento")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.data_vencimento &&
                      _vm.formatDateTime(_vm.boleto.data_vencimento, "date")
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Data do Crédito")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.data_credito &&
                      _vm.formatDateTime(_vm.boleto.data_credito, "date")
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Valor")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.valor &&
                      _vm.boleto.valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Valor de juros")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.type_interest == "%"
                      ? _vm.boleto.interest + " " + _vm.boleto.type_interest
                      : _vm.boleto.interest &&
                          _vm.boleto.type_interest +
                            " " +
                            _vm.boleto.interest.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Aplicar juros após")]
              ),
              _c("td", [_vm._v(_vm._s(_vm.boleto.interest_days) + " dias.")]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Valor de multa")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.type_fee === "%"
                      ? _vm.boleto.fee + " " + _vm.boleto.type_fee
                      : _vm.boleto.fee &&
                          _vm.boleto.type_fee +
                            " " +
                            _vm.boleto.fee.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                  ) + " "
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Aplicar multa após")]
              ),
              _c("td", [_vm._v(_vm._s(_vm.boleto.fee_days) + " dias.")]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Nosso número")]
              ),
              _c("td", [_vm._v(_vm._s(_vm.boleto.nosso_numero))]),
            ]),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _c("vs-divider", { attrs: { position: "left" } }, [
                    _vm._v("Banco"),
                  ]),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Cód. do Banco")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.config_boleto &&
                      _vm.boleto.config_boleto.bank_account.bank_branche.bank
                        .bank_code
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Nome do Banco")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.config_boleto &&
                      _vm.boleto.config_boleto.bank_account.bank_branche.bank
                        .name
                  )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Agência")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.config_boleto &&
                      _vm.boleto.config_boleto.bank_account.bank_branche.code
                  ) +
                    " - " +
                    _vm._s(
                      _vm.boleto.config_boleto &&
                        _vm.boleto.config_boleto.bank_account.bank_branche.dv
                    )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "font-semibold",
                  staticStyle: { "padding-right": "10px" },
                },
                [_vm._v("Conta")]
              ),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.boleto.config_boleto &&
                      _vm.boleto.config_boleto.bank_account.code
                  ) +
                    " - " +
                    _vm._s(
                      _vm.boleto.config_boleto &&
                        _vm.boleto.config_boleto.bank_account.dv
                    )
                ),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _c("vs-divider"),
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 flex flex-wrap items-center justify-end",
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "ml-auto mb-2",
                            attrs: { color: "secondary" },
                            on: {
                              click: function ($event) {
                                _vm.popupBoletoDetails = false
                              },
                            },
                          },
                          [_vm._v("Fechar")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Boleto(s)",
            active: _vm.popupShowBoleto,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowBoleto = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcBoleto, width: "100%" },
          }),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c("vs-divider"),
              _c("div", { staticClass: "vx-col w-full mt-0" }, [
                _c(
                  "div",
                  { staticClass: "mt-2 flex flex-wrap items-center justify" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "w-full",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.downloadRemessa.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "!! Clique aqui para baixar o arquivo de remessa !!"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Operações com Boletos da Movimentação",
            active: _vm.localShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-col w-full" }, [
            _c(
              "div",
              {
                staticClass: "mt-2 flex flex-wrap items-center justify-between",
              },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "w-full mb-2",
                    on: { click: _vm.generateNewBoleto },
                  },
                  [_vm._v("Gerar Um NOVO Boleto Para esta Transação")]
                ),
              ],
              1
            ),
          ]),
          _vm.transaction.boleto.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.transaction.boleto, hoverFlat: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (boleto, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c("vs-td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          boleto.status == "1"
                                            ? "Gerado"
                                            : boleto.status == "06"
                                            ? "Liquidado"
                                            : "Por favor avise o suporte"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      " " + _vm._s(boleto.nosso_numero) + " "
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          boleto.data_vencimento &&
                                            _vm.formatDateTime(
                                              boleto.data_vencimento,
                                              "date"
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          boleto.data_documento &&
                                            _vm.formatDateTime(
                                              boleto.data_documento,
                                              "date"
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          boleto.data_credito &&
                                            _vm.formatDateTime(
                                              boleto.data_credito,
                                              "date"
                                            )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(boleto.transaction_cfc_id) +
                                        " "
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          boleto.valor.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass:
                                            "h-5 w-5 ml-2 inline-flex",
                                          attrs: {
                                            text: "Imprimir 2ª Via do Boleto",
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "PrinterIcon",
                                              svgClasses:
                                                "h-5 w-5 hover:text-primary cursor-pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.generate2viaBoleto(
                                                  boleto
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass:
                                            "h-5 w-5 ml-2 inline-flex",
                                          attrs: {
                                            text: "Detalhes",
                                            position: "top",
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "EyeIcon",
                                              svgClasses:
                                                "h-5 w-5 hover:text-primary cursor-pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.seeBoletoDetails(
                                                  boleto
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center justify-end",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "ml-auto mb-2",
                                                attrs: { color: "secondary" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.localShow = false
                                                  },
                                                },
                                              },
                                              [_vm._v("Fechar")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1554782307
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Status")]),
                      _c("vs-th", [_vm._v("Nosso nº")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Data do Doc.")]),
                      _c("vs-th", [_vm._v("Data do Crédito")]),
                      _c("vs-th", [_vm._v("Nº do Doc.")]),
                      _c("vs-th", [_vm._v("Valor")]),
                      _c("vs-th", [_vm._v("Ação")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.transaction.boleto.length
            ? _c(
                "vs-alert",
                {
                  staticClass: "mt-4 mb-4",
                  staticStyle: { height: "auto" },
                  attrs: {
                    active: true,
                    color: "warning",
                    "icon-pack": "feather",
                    icon: "icon-info",
                  },
                },
                [
                  _c("span", [
                    _vm._v("Nenhum boleto emitido para esta transação."),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }