<template>
  <div>
    <vs-popup class="popup50" :fullscreen="false" :title="titleBoletoModal" :active.sync="popupBoletoDetails">
      <table width="100%">
        <tr><td colspan="2"><vs-divider position="left">Dados Gerais</vs-divider></td></tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Status</td>
          <td>{{ boleto.status == '1' ? 'Gerado' : 'Liquidado'}}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Data de Processamento</td>
          <td>{{ boleto.data_processamento && formatDateTime(boleto.data_processamento, 'date') }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Data do Documento</td>
          <td>{{ boleto.data_documento && formatDateTime(boleto.data_documento, 'date') }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Data de Vencimento</td>
          <td>{{ boleto.data_vencimento && formatDateTime(boleto.data_vencimento, 'date') }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Data do Crédito</td>
          <td>{{ boleto.data_credito && formatDateTime(boleto.data_credito, 'date') }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Valor</td>
          <td>{{ boleto.valor && boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Valor de juros</td>
          <td>{{ boleto.type_interest == '%' ? boleto.interest + ' ' + boleto.type_interest : boleto.interest && boleto.type_interest + ' ' + boleto.interest.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Aplicar juros após</td>
          <td>{{ boleto.interest_days }} dias.</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Valor de multa</td>
          <td>{{ boleto.type_fee === '%' ? boleto.fee + ' ' + boleto.type_fee : boleto.fee && boleto.type_fee + ' ' + (boleto.fee).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }} </td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Aplicar multa após</td>
          <td>{{ boleto.fee_days }} dias.</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Nosso número</td>
          <td>{{ boleto.nosso_numero }}</td>
        </tr>
        <tr><td colspan="2"><vs-divider position="left">Banco</vs-divider></td></tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Cód. do Banco</td>
          <td>{{ boleto.config_boleto && boleto.config_boleto.bank_account.bank_branche.bank.bank_code }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Nome do Banco</td>
          <td>{{ boleto.config_boleto && boleto.config_boleto.bank_account.bank_branche.bank.name }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Agência</td>
          <td>{{ boleto.config_boleto && boleto.config_boleto.bank_account.bank_branche.code }} - {{ boleto.config_boleto && boleto.config_boleto.bank_account.bank_branche.dv }}</td>
        </tr>
        <tr>
          <td class="font-semibold" style="padding-right: 10px">Conta</td>
          <td>{{ boleto.config_boleto && boleto.config_boleto.bank_account.code }} - {{ boleto.config_boleto && boleto.config_boleto.bank_account.dv }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <vs-divider></vs-divider>
            <div class="vx-col w-full">
              <div class="mt-2 flex flex-wrap items-center justify-end">
                <vs-button class="ml-auto mb-2" color="secondary" @click="popupBoletoDetails = false">Fechar</vs-button>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </vs-popup>

    <vs-popup class="popup90" :fullscreen="false" title="Boleto(s)" :active.sync="popupShowBoleto">
      <object :data="srcBoleto" width="100%" style="height: 600px" />
      <div class="vx-row">
        <vs-divider></vs-divider>
        <div class="vx-col w-full mt-0">
          <div class="mt-2 flex flex-wrap items-center justify">
            <vs-button @click.prevent="downloadRemessa" class="w-full">!! Clique aqui para baixar o arquivo de remessa !!</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-popup class="popup90" :fullscreen="false" title="Operações com Boletos da Movimentação" :active.sync="localShow">
      <!-- BOLETOS -->
      <div class="vx-col w-full">
        <div class="mt-2 flex flex-wrap items-center justify-between">
          <vs-button class="w-full mb-2" @click="generateNewBoleto">Gerar Um NOVO Boleto Para esta Transação</vs-button>
        </div>
      </div>

      <vs-table v-if="transaction.boleto.length" :data="transaction.boleto" hoverFlat style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>Status</vs-th>
          <vs-th>Nosso nº</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Data do Doc.</vs-th>
          <vs-th>Data do Crédito</vs-th>
          <vs-th>Nº do Doc.</vs-th>
          <vs-th>Valor</vs-th>
          <vs-th>Ação</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(boleto, i) in data" style="opacity: 1 !important">
            <vs-td> {{ boleto.status == '1' ? 'Gerado' : (boleto.status == '06' ? 'Liquidado' : 'Por favor avise o suporte') }} </vs-td>
            <vs-td> {{ boleto.nosso_numero }} </vs-td>
            <vs-td> {{ boleto.data_vencimento && formatDateTime(boleto.data_vencimento, 'date') }} </vs-td>
            <vs-td> {{ boleto.data_documento && formatDateTime(boleto.data_documento, 'date') }} </vs-td>
            <vs-td> {{ boleto.data_credito && formatDateTime(boleto.data_credito, 'date') }} </vs-td>
            <vs-td> {{ boleto.transaction_cfc_id }} </vs-td>
            <vs-td> {{ boleto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }} </vs-td>
            <vs-td>
              <!-- 2ª VIA -->
              <vx-tooltip text="Imprimir 2ª Via do Boleto" position="top" class="h-5 w-5 ml-2 inline-flex">
                <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="generate2viaBoleto(boleto)" />
              </vx-tooltip>
              <!-- DETALHES -->
              <vx-tooltip text="Detalhes" position="top" class="h-5 w-5 ml-2 inline-flex">
                <feather-icon icon="EyeIcon" @click="seeBoletoDetails(boleto)" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" />
              </vx-tooltip>
            </vs-td>
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="8">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center justify-end">
                  <vs-button class="ml-auto mb-2" color="secondary" @click="localShow = false">Fechar</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-alert v-if="!transaction.boleto.length" class="mt-4 mb-4" :active="true" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Nenhum boleto emitido para esta transação.</span>
      </vs-alert>
    </vs-popup>

  </div>
</template>

<script>
import axios from '@/axios.js'

// Store Module
import moduleTransaction from '@/store/transaction/moduleTransaction.js'
import formatDateTime from '@/util/formatDateTime.js'

export default {
  // components: {
  //   vSelect
  // },
  props: {
    show: {
      type: Boolean, default: false
    },
    transactionProp: {
      type: Object
    }
  },

  computed: {
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      } else {
        this.transaction = this.transactionProp
      }
    }
  },

  created () {
    if (!moduleTransaction.isRegistered) {
      this.$store.registerModule('transaction', moduleTransaction)
      moduleTransaction.isRegistered = true
    }
  },

  data () {
    return {
      localShow: false,
      disableSave: false,
      popupShowBoleto: false,
      srcBoleto: '',
      remessaUrl: '',

      titleBoletoModal: '',
      popupBoletoDetails: false,
      boleto: {},

      transaction: {
        boleto: []
      }
    }
  },

  methods: {
    cancel () {
      this.$emit('cancel')
    },
    formatDateTime (dateTimeString, type = null) {
      return formatDateTime(dateTimeString, type)
    },
    async generate2viaBoleto (boleto) {
      this.$vs.loading()
      const srcBoleto = await axios.get(`${process.env.VUE_APP_API_URL}/reGenerate/boleto/${boleto.id}`)
      this.srcBoleto = `data:application/pdf;base64,${srcBoleto.data}`
      this.remessaUrl = `${process.env.VUE_APP_API_URL}/reGenerate/remessa/${boleto.id}`
      this.popupShowBoleto = true
      this.$vs.loading.close()
    },
    downloadRemessa () {
      this.$vs.loading()
      axios.get(this.remessaUrl)
        .then(response => {
          const blob = new Blob([response.data.remessa], { type: 'text/plain' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = response.data.filename
          link.click()
          URL.revokeObjectURL(link.href)
          this.$vs.loading.close()
        }).catch(console.error)
    },
    seeBoletoDetails (data) {
      this.boleto = JSON.parse(JSON.stringify(data))
      this.titleBoletoModal = `Detalhes do boleto ${data.id}`
      this.popupBoletoDetails = true
    },
    async generateNewBoleto () {
      try {
        this.$vs.loading()
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/generate/boleto`, this.transaction)
        this.srcBoleto = `data:application/pdf;base64,${response.data.boletoPdf}`
        this.remessaUrl = `${process.env.VUE_APP_API_URL}/reGenerate/remessa/${response.data.boleto.id}`
        this.transaction.boleto.push(response.data.boleto)
        this.popupShowBoleto = true
        this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close()
        this.deleteTransactionNode = {}
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}

</style>
